@media (max-width: 768px) {
    body {
        min-width: 0px;
        padding-top: 70px;
    }

    .faq {
        width: 90% !important;
        margin-top: 0 !important;
    }

    .faq h1{
        font-size: 28px;
    }

    .faq .header{
        height: auto;
    }

    .reg-plate {
        background-size: cover;
        width: 300px;
        height: 70px;
        min-width: 0;
        min-height: 0;
        position: relative;
        overflow: hidden;
    }

    .reg-button-cont {
        width: 300px;
        height: auto;
    }

    .reg-button {
        width: 100%;
        max-width: 0px;
        min-width: 250px;
        background-image: url('./img/Get_Score.svg');
        background-size: contain;
        padding: 20px;
        background-repeat: no-repeat;
        background-position: center center;
        color: white;
        font-size: 1.3em;
        font-family: 'Overpass', sans-serif;
        font-weight: bold;
        background-color: transparent;
        border-color: transparent;
        margin-left: auto;
        margin-right: auto;
    }

    .btn-padding {
        padding: 5px 15px 5px 10px
    }

    .reg-plate input {
        margin-right: 13px;
        padding-left: 20%;
        margin-top: 0;
        font-weight: bold;
        background-color: transparent;
        border: none;
        width: 100%;
        min-height: 100px;
        height: 100%;
        font-size: 4em;
        float: right;
        top: -50px;
        margin-left: 18%;
        padding-top: 30px;
        line-height: 100%;
    }

    .reg-plate-yellow input {
        margin-right: 13px;
        padding-left: 20%;
        margin-top: 0;
        font-weight: bold;
        background-color: transparent;
        border: none;
        width: 100%;
        min-height: 100px;
        height: 100%;
        font-size: 4em;
        float: right;
        top: -30px;
        margin-left: 18%;
        padding-top: 30px;
    }

    .reg-plate-yellow {
        background-size: cover;
        width: 300px;
        height: 70px;
        min-width: 0;
        min-height: 0;
        position: relative;
    }

    .overpass-font {

        width: 50px;

    }

    .search-text {
        width: 80%;
        text-align: center;
        font-family: 'Overpass', sans-serif;
        font-size: 4vw;
        margin-left: auto;
        margin-right: auto;
    }

    .search-text-bottom {
        width: 100%;
        text-align: center;
        font-family: 'Overpass', sans-serif;
        font-size: 2vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    /* NAV */
    .links {
        width: 100%;
        height: 100%;
        float: right;
    }

    .logo {
        width: 92px;
        height: 50px;
        background-image: url('./img/NewLogo.svg');
        background-size: cover;
        z-index: 1;
        position: relative;
        left: 0;
        margin: 0;
    }

    .signup {
        width: 170px;
        height: 50px;
        display: inline;
        float: left;
        border-left: none;
        margin-top: 15px;
        margin-left: 20px;
    }

    .signupp {
        width: 120px;
        height: 50px;
        display: inline;
        float: left;
        border-left: none;
        margin-top: 5px;
        margin-left: 20px;
        padding: 20px;
    }

    .signin {
        width: 100px;
        height: 50px;
        display: inline;
        float: right;
        border-left: none;
        margin-top: 0px;
        margin-left: 20px;
    }


    .signup p {
        font-size: 11px;
    }

    /* LOG IN */
    .logincontain {
        width: 95%;
        height: 100%;
        border-radius: 12px;
        background-color: white;
        padding: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        box-shadow: 0px 9px 13px rgba(61, 61, 61, 0.30);
    }

    .circle-yellow {
        height: 5px;
        width: 5px;
        margin-top: 3px !important;
        background-color: #ffcc00;
        float: left;
        margin-right: 5px;
        border-radius: 20px;
    }

    .regcontain {
        width: 95%;
        height: 300px;
        border-radius: 12px;
        background-color: white;
        padding: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        box-shadow: 0px 9px 13px rgba(61, 61, 61, 0.30);
    }

    .btn-left {
        padding: 0 !important;
        float: none;
        text-align: center;
    }

    .page-header h1 {
        font-size: 7vw;
        margin-bottom: 25px;
    }

    .scorebg {
        margin-top: 100px;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        padding: 15px;
        box-shadow: 2px 2px 20px grey;
        min-width: 270px;
    }

    .containers {
        margin-top: 100px;
        margin-bottom: 20px;
    }

    .logincontain {
        margin-top: 100px;
    }

    .under {
        overflow: auto !important;
    }

    .starsimage {
        min-width: 0;
        width: 100%;
        padding-bottom: 0px;
    }

    .hei {
        width: 50% !important;
        margin-bottom: 10px;
    }

    .container-login {

    }

    .card-bottomm {
        height: 130px;

    }

    .heii {
        width: 40% !important;
        margin-bottom: 20px;
        margin-left: 10px;
        padding-top: 11% !important;
    }

    .capped {
        width: 10em;
        overflow: hidden;
        max-height: 20px !important;
    }

    .btn-info {
        font-size: 10px !important;

    }

    h5 {
        margin: 0 !important;
        padding: 0 !important;
    }

    h6 {
        margin: 0 !important;
        padding: 0 !important;
    }

    .reg-background {
        margin-top: 10px !important;
        height: 31px !important;
        width: 140px !important;
    }

    .score-reg {
        font-size: 1.4em !important;
        padding-top: 5px;
        text-align: center;
    }

    .toppp {
        font-size: 2em;
        width: 50%;
        border-bottom: solid 2px #ffcc00;
    }

    .edit-half-bottom {
        margin-top: 15px;
        width: 100%;
    }

    .under {

        width: 50%;
        float: left;
    }

    .btn-primary {
        width: 100% !important;
        height: 40px !important;
        line-height: 20px !important;
        font-size: 0.8em !important;
        margin-left: 2px;
    }

    .mot-test-font {
        font-size: 8px !important;
    }

    .btn-hpi {
        width: 140px !important;
        height: 25px !important;
        font-size: 0.8em !important;
        line-height: 0px !important;
    }

    .btn-home {
        width: 100% !important;
        height: 40px !important;
        font-size: 0.8em !important;
        line-height: 20px !important;
        margin-left: 2px;
    }

    .btn-ea {
        width: 100% !important;
        line-height: 20px !important;
        height: 40px !important;
        font-size: 0.8em !important;
        margin-left: 2px;
    }


    .underr {

        width: 50%;
        float: right;
    }

    .full-check-top {
        width: 0%;
        float: right;
    }

    .regsignuph {
        font-size: 24px !important;
        min-width: 0;
    }

    .scoreea {
        height: auto;
        width: 100%;
        float: right;
    }

    .hchange {
        font-size: 2.5vw;
    }

    .eatext {
        font-size: 1.6vw;
    }

    .left {
        font-size: 0.3em !important;
        font-family: 'Overpass bold', sans-serif;
        padding-left: 0px !important;
    }

    .regfont {
        width: 100px;
        height: 25px;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        float: right;
        text-align: right;
        border-radius: 3px;
    }

    .coly {
        width: 100%;
        font-size: 3vw !important;
        text-align: center;
        overflow: hidden;
        float: right;
    }

    .arrow-down {
        width: 15px;
        height: 15px;
        margin-right: 0;
    }

    .Toastify__toast--error {
        margin-top: 64px;
        width: 90% !important;
        font-size: 8px;
        height: auto;
    }

    .Toastify__toast--info {
        margin-top: 64px;
        width: 90% !important;
        height: auto;
    }

    .Toastify__toast--success {
        margin-top: 64px;
        width: 90% !important;
        height: auto;
    }

    .Toastify__toast--warning {
        margin-top: 64px;
        width: 90% !important;
        height: auto;
    }

    .car-contain {
        height: 100%;
        width: 60px;
        margin-right: 10px;
        float: left;
        vertical-align: middle;
        padding-left: 5px;
    }

    .car-img-text {

        font-size: 14px;
        height: 100%;
        line-height: 28px;
    }

    .i-logo-home {
        width: 12px;
        height: 12px;
        background-image: url('./img/Info_Expand.svg');
        background-size: contain;
        background-color: grey;
        border-radius: 40px;
        display: inline-block;
        float: right;
        margin-right: 1rem;
        margin-top: 10px;
        vertical-align: middle;
    }

    .radius {
        width: 100% !important;
        height: 25px !important;
        padding: 0 !important;
        font-size: 8px !important;
    }

    .padding {
        width: 50% !important;
    }

    .container-score {
        width: 95%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;
        min-width: 0px;
    }

    .text-left {
        width: 100%;
        height: auto !important;
        float: right !important;
        padding: 0;
        margin: 0;
    }

    .image-right {
        float: none;
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 0% !important;
        overflow: visible !important;
        max-height: 0 !important;
        max-width: 0 !important;
    }

    .ad {
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        margin-bottom: 30px;
    }

    .home-font {
        text-align: center;
        width: 100%;
    }

    .result {
        font-size: 14px;
    }

    .smaller {
        width: 30% !important;
    }

    .leftyy {
        width: 70% !important;
        padding-left: 5px !important;
        font-family: 'Overpass', sans-serif !important;
    }

    .short-font {
        font-size: 1em !important;
        max-width: 195px;
        margin-left: auto;
        margin-right: auto;
    }

    .card-bottom {
        margin-top: 10px;
        width: 100%;
        height: 40px;
        background-color: #ecf0f1;
        padding-left: 20px;
    }

    .precheckcontainer {
        min-width: 0px;
        width: 100% !important;
        padding: 4px !important;
        padding-top: 20px !important;
        margin-top: 100px;
    }

    .delete-button {

        margin-right: 0;
        margin-bottom: 5px;
    }

    .edit-button {

        margin-right: 0;
        margin-bottom: 5px;
    }

    .prechecks {
        font-size: 8vw;
        border-bottom: solid 2px #ffcc00;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .precheckcontain {
        text-align: center;
        padding: 0 0 15px !important;
    }

    .mot-font {
        font-size: 7px !important;
    }

    .Toastify__toast-container {
        height: 100%;
        width: 100vw;
        position: fixed !important;
        z-index: 1;
    }

    .btn.prebutton {
        width: 55px !important;
        height: 65px !important;
        background-color: #000032 !important;
        color: #ffcc00 !important;
        border-radius: 5px;
        font-size: 8px !important;
        text-align: center;
        padding: 0 !important;
        display: inline-block;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .ea-font {
        font-size: 10px !important;
    }

    .btn.prebutton1 {
        width: 55px !important;
        height: 65px !important;
        background-color: #ffcc00 !important;
        color: #000032 !important;
        border-radius: 5px;
        font-size: 8px !important;
        text-align: center !important;
        display: inline-block;
        margin-left: 0 !important;
        position: absolute;
        padding: 0 !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1 !important;
        font-family: 'Overpass bold', sans-serif !important;
    }

    .pre {
        width: 100%;
        text-align: center;
        margin: 0px !important;
    }

    .prehei {
        height: auto;
        min-height: 140px;
        text-align: center;
        padding: 0 !important;
    }

    .ea-small {
        font-size: 8px;
        width: 95%;
        padding-bottom: 10px !important;
    }

    .score-left {
        width: 50% !important;
    }

    .col-left {
        width: 38% !important;
        padding: 10px;
        font-size: 30px;
        margin-left: 15px;
    }


    .button-selection {
        width: 50% !important;
    }

    .fullvehhead {
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .img-right {
        float: right;
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .example-box {
        height: 300px;
    }

    .top-gap {
        padding-top: 0;
        font-size: 20px;
    }

    .pad-top {
        padding-top: 0;
    }

    /* Style the navigation menu */
    .topnav {
        overflow: hidden;
        background-color: #333;
        position: relative;
    }

    /* Hide the links inside the navigation menu (except for logo/home) */
    .topnav #myLinks {
        display: none;
    }

    /* Style navigation menu links */
    .topnav a {
        color: white;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        display: block;
    }

    /* Style the hamburger menu */
    .topnav a.icon {
        background: black;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
    }

    /* Add a grey background color on mouse-over */
    .topnav a:hover {
        background-color: #ddd;
        color: black;
    }

    /* Style the active link (or home/logo) */
    .active {
        background-color: #4CAF50;
        color: white;
    }

    ul {
        padding: 0;
        list-style-type: none;
    }


    .phone {
        background-color: #36383F;
        border-radius: 40px;
        width: 300px;
        height: 600px;
        Display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 30px 50px 100px #85888C;
    }

    .content {
        border: 1px solid #36383F;
        border-radius: 20px;
        width: 94%;
        height: 91%;
        background-color: #F5F6FA;
        overflow: hidden;
    }

    nav {
        background-color: rgb(255, 204, 0);
        width: 100%;
        height: 70px;
        position: fixed;
        top: 0;
        z-index: 999;
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: flex !important;
    }

    #menuToggle input {
        display: flex;
        width: 40px;
        height: 32px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
    }

    #menuToggle span {
        display: flex;
        width: 29px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        background: black;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 5px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        opacity 0.55s ease;
    }

    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
        transform-origin: -5% 0%;
    }

    #menuToggle input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-3px, -1px);
        background: #36383F;
    }

    #menuToggle input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    #menuToggle input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);

    }

    #menu {
        position: absolute;
        width: 200px;
        height: 100rem;
        box-shadow: 0 0 10px #85888C;
        right: 0;
        padding: 100px 0;
        background-color: #F5F6FA;
        -webkit-font-smoothing: antialiased;
        transform-origin: 100% 100%;
        transform: translate(100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
        list-style: none;
    }

    #menu a {
        color: black;
        font-family: 'Overpass', sans-serif;
        font-size: 20px;
    }

    #menuToggle input:checked ~ ul {
        transform: none;
    }

    .accord-contain {
        width: 100%;
    }

    .accord-contain .colour.card-header {
        padding: 15px 5px;
    }

    .accord-contain h5{
        padding-right: 25px !important;
        position: relative;
        font-size: 18px;
    }

    .accord-contain h5 .arrow-down{
        width: 15px;
        height: 15px;
        margin-right: 0;
        position: absolute;
        right: 0;
        top: 6px;
    }
}