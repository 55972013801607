@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;600;700&display=swap');

@font-face {
  font-family: 'UKNumberPlate';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('./fonts/uk-number-plate-cufonfonts-webfont/UKNumberPlate.woff') format('woff'),
  url('./fonts/uk-number-plate-cufonfonts-webfont/UKNumberPlate.ttf') format('ttf');
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {@font-face {font-family: 'UKNumberPlate';
    src: url('./fonts/uk-number-plate-cufonfonts-webfont/UKNumberPlate.ttf') format('ttf');
    }}

#root{
  overflow-x: hidden;
}
html{
  height: 100%;
}
body{
  min-width: 630px;
}

.margin-none{
  margin: 0;
}

input, button, submit {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
 }
.jumbotron{
  margin-top: 80px;
  max-width: 1300px;
  box-shadow: 2px 2px 20px grey;
}
.yellow{
  background-color: #ffcc00;
}
.short-font{
  font-size: 2em !important;
  max-width: 195px;
  margin-left: auto;
  margin-right: auto;
}
.logolink{
  height: 50px;
  width: 50px;
}
.card{
  border: red !important;
  border-radius: 5px !important;
}
.shortlist-col{
  background-color: #ffcc00 !important;
  border: white;
  border-radius: 10px !important;
  margin-bottom: 10px;
  margin-top: 10px;
  padding:0 !important;
  padding-bottom: 10px !important;
  overflow: hidden !important;
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.sort{
  width: 100px;
  height: 40px;
  background-color: #ffcc00;
  margin-left: 5px;
  border-radius: 10px;
  border: transparent;
}
.onecheck{
  height: 400px;
  width: 200px;
  background-image:url('./img/onecheck.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  opacity: 0.9;
}
.threecheck{
  height: 400px;
  width: 200px;
  background-image:url('./img/threecheck.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  opacity: 0.9;
}
.fivecheck{
  height: 400px;
  width: 200px;
  background-image:url('./img/fivechecks.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
    opacity: 0.9;
}
.border{
  border-radius: 10px;
  opacity: 1;
}
.yelloww{
  background-color: #ffcc00 !important;
}
.spacing{
  padding: 2px;
}
.transparent{
  background-color: transparent !important;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
/* layout */
.reg-plate{
  background-image:url('./img/pngreg.png');
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 65em;
  width: 100%;
  height: 15em;
  max-height: 220px;
  min-height: 120px;
  min-width: 760px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  text-align: center; 
  overflow:hidden;
}

.reg-plate input{
  padding: 0;
  margin: 0;
  border: 0 none;
  background: none;
  font-size: 10em;
  position: relative;
  top: -60px;
  margin-left: 20%;
  padding-top: 20px;
  font-family: 'UKNumberPlate', sans-serif !important;
}
.reg-plate input:focus{
  outline: none; 
  background-color: transparent;
  font-family: 'UKNumberPlate', sans-serif !important;
}
         
         

/* layout */
.reg-plate-yellow{
  background-image:url('./img/yellow_plate.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 50em;
  height: 12em;
  max-height: 220px;
  min-height: 80px;
  min-width: 460px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  text-align: center; 
  overflow:hidden;
  font-family: 'UKNumberPlate', 'Overpass', sans-serif !important;

}

.reg-plate-yellow input{
  padding: 0;
  margin: 0;
  border: 0 none;
  background: none;
  font-size: 10.5em;
  position: relative;
  top: -47px;
  margin-left: 17%;
  padding-top: 20px;
  font-family: 'UKNumberPlate', 'Overpass', sans-serif !important;

}
.reg-plate-yellow input:focus{
  outline: none; 
  background-color: transparent;
  font-family: 'UKNumberPlate', 'Overpass', sans-serif !important;
 
}
.contain-landing{
  margin-top:80px;
  width: 100%;
  height: 100%;
  /* background-color: red; */
  padding: 10px;
  background-image: url('./img/Cars_Background.svg');
  background-attachment: fixed;
  background-size: 100%;
}
.reg-button{
  width: 100%;
  max-width: 360px;
  min-width: 230px;
  background-image:url('./img/Get_Score.svg');
  background-size: contain;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  font-size: 2em; 
  font-family: 'Overpass', sans-serif;
  font-weight: bold;  
  background-color: transparent;
  border-color: transparent;
  margin-left: auto;
  margin-right: auto;
}
.reg-button:hover{
  opacity: 0.9;
}
.search-text{
  width: 50%;
  max-width: 800px;
  text-align: center;
  font-family: 'Overpass', sans-serif;
  font-size: 2vw;
  margin-left: auto;
  margin-right: auto;
}
.search-text-bottom{
  width: 50%;
  max-width: 800px;
  text-align: center;
  font-family: 'Overpass', sans-serif;
  font-size: 1.2vw;
  margin-left: auto;
  margin-right: auto;
}
.arrows-contain{
  width: 100%;
  height: 50px;
  padding: 40px;
  margin-bottom: 20px;
}
.arrows{
  width: 25px;
  height: 25px;
  background-image: url('./img/Arrows.svg');
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
}

/* NAV */
.logo-home-link{
  width: 70px;
  height: auto;
  background-color: #000032;
  border-radius: 3px 3px 3px 3px;
  border: none;
  border-width: 1px 1px 1px 1px;
  padding: 6px 12px 6px 12px;
  color: #fff;
  line-height: 15px;
  text-transform: uppercase;
  z-index: 2;
  font-size: 10px;
}

.logo-home-link:hover{
  color: #FFF;
  box-shadow: 0 0 5px gray;
}

@media screen and (min-width: 768px) {
  .logo-home-link{
    width: auto;
    padding: 12px 20px;
    height: auto;
    font-size: 14px;
  }
}

.logo{
  width: 152px;
  height: 82px;
  background-image:url('./img/NewLogo.svg');
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  z-index: 200;
  position: absolute;
  left: 50%;
  margin-left: -76px;
}

nav{
  height: 100px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
  background-color: rgba(255,204,0, 0.9);
  text-align: center;
  z-index: 999;
  padding: 0 1rem !important;
}

#menuToggle {
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
}

#menuToggle input
{
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span
{
  display: flex;
  width: 29px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: black;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}
.desktop-card-left-col{
  width: 70% !important;
}
.desktop-card-right-col{
  width: 30% !important;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: -5% 0%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383F;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
  
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem;
}

@media (max-width: 768px) {
  .navbar{
    position: fixed !important;
    width: 100%;
  }
}

#menu
{
  position: absolute;
  width: 200px;
  height: 100rem;
  box-shadow: 0 0 10px #85888C;
  right: 0;
  top: 0;
  padding: 100px 0;
  background-color: #F5F6FA;
  -webkit-font-smoothing: antialiased;
  transform-origin: 100% 100%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu a{
  color: black;
  font-family: 'Overpass', sans-serif;
  font-size: 20px;
}
#menuToggle input:checked ~ ul
{
  transform: none;
}
.links{
  width: 50%;
  min-width: 395px;
  height: 100%;
  display: flex;
  align-items: center;
}
.links p{
  color: black;
  display: inline;

}
.create-bold{
  font-family: 'Overpass bold', sans-serif;
}
.navcontain{
  height: 100%;
}
.signup{
width: 200px;
height: 50px;
display: inline;
border-left: solid;
}
.signupp{
  width: 200px;
  height: 50px;
  display: inline;
  padding: 20px;

  }
.signin{
  width: 110px;
  height: 100%;
  display: inline;
  text-align: center;
  padding: 15px;
}
.signin.user{
  display: inline-block;
  vertical-align: middle;
  padding: 5px 0;
  margin: 0 20px;
}
.logout{
  width: 150px;
  height: 100%;
  display: inline;
  float: left;
  text-align: center;
}
.signinpic{
  width: 50px;
  height: 50px;
  background-image:url('./img/Profile_Icon.svg');
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;

  
}
.shortlistpic{
  width: 40px;
  height: 50px;
  background-image:url('./img/ShortList_Icon.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  
}
.hide-sm{
  float: left;
}


/* REGISTER */
.regcontain{
  width: 60%;
  height: 300px;
  border-radius: 12px;
  background-color: white;
padding: 50px;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
box-shadow: 0px 9px 13px rgba(61, 61, 61, 0.30);
}
.reg-button-cont{
  width: 30%;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.logincontain{
  width: 60%;
  height: 100%;
  border-radius: 5px;
  background-color: white;
padding: 50px;
margin-left: auto;
margin-right: auto;
max-width: 600px;
margin-top: 50px;
box-shadow: 0px 9px 13px rgba(61, 61, 61, 0.30);
}
.eabuttons{
  width: 100%;
  height: 50px;
  margin-top: 20px;
}
.back{
  height: 40px;
  width: 80px;
  float: left;
  background-image:url('./img/Back.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.back-btn{
  color: rgb(24, 154, 197) !important;
}
.prehei{
 height: auto;
 min-height: 140px;
  text-align: center;
  margin-bottom: 10px;
}
.padding-top{
  margin-top: 10px !important;
}
.preheig{
  height: 200px !important;
  
}
.prewid{
  width: 300px !important;
}
.precentre{
width: 1px;
height: 1px;
text-align: center;
position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.btn.prebutton{
  width: 80px !important;
  height: 60px !important;
  background-color: #000032 !important;
  color: #ffcc00 !important;
  border-radius: 5px;
  font-size: 10px !important;
  text-align: center;
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-wrap: break-word;
  white-space: normal;
  padding: 0 10px !important;
}
.btn.prebutton1{
  width: 80px !important;
  height: 60px !important;
  background-color: #ffcc00 !important;
  color: #000032 !important;
  border-radius: 5px;
  font-size: 10px !important;
  text-align: center;
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1 !important;
  font-family: 'Overpass', sans-serif !important;
  overflow-wrap: break-word;
  white-space: normal;
  padding: 0 10px !important;
}

.prebutton p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.prewid p{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pre{
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.pree{
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.butcol{
  width: 50%;
  margin: 5px;
  height: 100px !important;
  /* background-color: yellow; */
}
.pref{
  width: 100%;
}

.edit-desktop{
  margin-right: 10px;
  padding-right: 10px;
  border-right: solid 1px black;
}
.prebutton1 p {
  margin: 0;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prebuthei{
background-color: green;
width: 50px !important;
}
.tester4{
  background-color: yellow;
}

.next{
  height: 40px;
  width: 80px;
  margin-left:auto;
  margin-right: auto;
  float: right;
  background-image:url('./img/Next.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.container-pre{
  position: relative !important;
  background-color: blue !important;
  width: 100%;


}
.jumbrotron{
  height: fit-content !important;
}
.prechecktable{
  width: 100%;
  height: 750px;
  background-color: grey;
}
.prechecktablee{
  width: 100%;
  height: 100%;
}
.prechecktableee{
  width: 100%;
  height: 720px;
}
.prechecktableeee{
  width: 100%;
  height: 380px;

}.container-fluid{
  background-color: red !important;
}
.precheckcontainer{
  width: 80%;
  height: 100%;
  min-width: 555px;
  border-radius: 12px;
  min-width: 720px;
  background-color: white;
padding: 50px;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
box-shadow: 0px 9px 13px rgba(61, 61, 61, 0.30);
}
.coll{
  width: 20%;
}
.heit{
  background-color: red;
  padding: 10px;
  float: left !important;
}
.editcontain{
  width: 60%;
  height: 100%;
  border-radius: 12px;
  background-color: white;
padding: 50px;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
box-shadow: 0px 9px 13px rgba(61, 61, 61, 0.30);
}
.viewmore{
  width: 50px !important;
  height: 50px !important;
}
.col-one{
  text-align: center;
  font-weight: bold;
  font-family: 'Overpass', sans-serif;
width: 30% !important;
margin-top: 5px;
}
.col-onee{
  text-align: center;
  font-weight: bold;
  font-family: 'Overpass', sans-serif;
width: 30% !important;
background-image:url('./img/pngreg.png');
background-size: contain;
background-repeat: no-repeat;
background-position: center center;
}
.col{
  font-size: 20px;
  font-family: 'Overpass', sans-serif;

}
.signeafont{
  font-size: 1vw;
}
#menu {
  list-style: none;
}
.re{
  background-image:url('./img/pngreg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.fonthead{
  font-size: 15px;
}
.reg-font{
  font-size: 3vw;
  float:right;
  margin-right: 5px;
}
.coly{
  width: 215px;
  font-size: 20px;
  font-family: 'Overpass', sans-serif;
  text-align: center;
  overflow: hidden;
}
.butt{
  text-decoration: none;
  background-color: transparent;
  border: none;
}
.form-control{
  width: 200px;
}
.col-end{
  width: 5%;
  text-align: right;
}
.loading-screen{
  z-index: 9999;
  position: fixed !important;
  width: 100% !important;
  text-align: center;
  height: 1000em !important;
  top:0;
  vertical-align: middle;
  background-color: rgba(92, 92, 92, 0.3);
}
.loading-screen svg{
  position: sticky;
  width: 8em;
  margin-top: 200px;
  text-align: center;
  vertical-align: middle;
}
.regfont{
  width: 90%;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
  font-size: 32px;
 float:right;
}
.small{
  width: 20% !important;
  background-color: red;
}
.smaller{
  width: 100px !important;
  text-align: center;
  float:left !important;
}
.big{
  width: 80% !important;
  background-color: red;
}
.boxx{
  text-decoration: none;
  width: 4em;
  font-size: 1.5em;
  margin-left: auto;
  margin-right: auto;
  max-width: 120px;
  overflow: hidden;
  font-family: 'Overpass', sans-serif !important;
  font-weight: bold;
}
.coltext[type=button]{
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  font-size: 1.5em;
  max-width: 5rem;
  max-height: 30px;
  overflow: hidden;
  font-weight: bold;
  font-family: 'Overpass', sans-serif !important;
}
.reg-button[type=button]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;

}

.cards-textss[type=button]{
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  overflow: hidden;
  font-weight: bold;
  font-family: 'Overpass', sans-serif !important;
}
.cards-texts[type=button]{
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  overflow: hidden;
  font-weight: bold;
  font-family: 'Overpass', sans-serif !important;
}
.margin-bottom{
  margin-bottom: 10px !important;
}
.left-arrow{
  width: 20px;
  height: 24px;
  background-image:url('./img/arrow-left.png');
background-size: contain;
background-repeat: no-repeat;
background-position: center center;
transform: rotate(180deg);
display: inline-block;
bottom: 0;
vertical-align: middle;
float: right;
}
.boxx[type=button]{
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  width: 4em;
  font-size: 1.5em;
  margin-left: auto;
  margin-right: auto;
  max-width: 120px;
  overflow: hidden;
}
.card-buttons{
  display: flex;
  flex-direction: column;
}

.card-buttons input[type=submit]{
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  border: none;
  width: 70px;
  margin-bottom: 5px;
  background-color: #ecf0f1;
  vertical-align: middle;
  line-height: 35px;
  height: 35px;
  display: inline-block;
  text-align: center;
  align-self: flex-end;
}
.price-space{
width: 10px;
}
.ree{
  background-image:url('./img/pngreg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 160px;
  border-radius: 8px;
}
.card-reg{
  background-image:url('./img/pngreg.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  height: 35px;
  border-radius: 8px;
  margin-bottom: 5px;
}
.reet{
  min-width: 160px;
}
.mi{
  min-width: 900px;
}
.font{
  font-size: 40px;
  font-family: 'Overpass bold', sans-serif;
}
.col-short{
  width: 12%;
  font-size: 20px;
  font-family: 'Overpass', sans-serif;
  text-align: center;
  overflow: hidden;

}
.plus{
  margin: 25px;
  min-width: 840px;
  padding-top: 20px;
  box-shadow: 1px 1px 5px 1px lightgrey;
  border-radius: 5px !important;

}
.marg{
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
}
.pricenew{
  width: 70px;
  margin-left: auto;
  margin-right:auto;
}
.colar{
  width: 16%;
  display: inline-block;
  max-width: 14px !important;
  margin-left: 5px;
}
.top{
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.topp{
  width: 100%;

  display: block;
  float: right;
}
.toppp{
  font-size: 4em;
  width: 50%;
  border-bottom: solid 2px #ffcc00;
  font-family: 'Overpass', sans-serif;
  font-weight: bold;
}
.precheckcontain{
  width: 100%;
  padding: 10px;
}
.make-box{
  width: 10rem !important;
  max-width: 10rem !important;
}
.prechecks{
  font-size: 4em;
  border-bottom: solid 2px #ffcc00;
}
.completecontain{
  width: 45%;
  height: 100%;
  border-radius: 12px;
  background-color: white;
padding: 50px;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
box-shadow: 0px 9px 13px rgba(61, 61, 61, 0.30);
}
.regheader{
  width: 100%;
  display: inline-block;
}
.regsignup{
  border-bottom: 3px solid #ffcc00;
  width: 50%;
  text-align: center;
  float: left;
}

.regsignin{
  opacity: 0.6;
  border-bottom: 3px solid lightgrey;
  width: 50%;
  text-align: center;
  float: left;
}
.regsignupl{
  opacity: 0.6;
  border-bottom: 3px solid lightgrey;
    width: 50%;
    text-align: center;
    float: left;
  }
  .regsigninl{
    border-bottom: 3px solid orange;
    width: 50%;
    text-align: center;
    float: left;
  }
 .ea-font{
   font-size: 14px;
   width: 100%;
   padding: 0 10px;
 }

/* SCORE */
.scorehead{
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
}
.red{
  color: red !important;
  border-radius: 10px;
}
.green{
  /* background-color: #7bed9f !important; */

  border-radius: 10px;
}
.scoreheadd{
  height: 100px;
  width: 100%;
  margin-bottom: 20px;
}
.grey-input input{
  background-color: #ecf0f1 !important;
  border: none;
}
.PhoneInputInput{
  min-height: 40px !important;
  border-radius: 5px;
}

.grey-input input:focus{
  background-color: #ecf0f1 !important;
}
.scoreheadtext{
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.container-score{
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .container-score{
    width: 80%;
  }
}

.stars{
  background-image:url('./img/Star_Normal.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 5px;
}
.fon{
  font-size: 13px;
  font-weight: lighter;
}
.starscase{
  width: 400px;
  float: left;
  background-color: blue;
  color:white;
  margin-top: 10px;
  padding: 10px;
}
.starstext{
  color: white;
}
.mot-test-font{
  font-size: 14px !important;
  padding:0;
  margin-bottom: 0 !important; 
  font-weight: 700;
  font-family: 'Overpass', sans-serif;
}
.mot-test-font-small{
  font-size: 7px !important;
  padding:0;
  margin-bottom: 0 !important; 
  font-family: 'Overpass', sans-serif;
}
.scorebg{
  margin-top: 50px;
  width: 100%;
  height: 100%;
  background-color:#ffffff;
  padding: 20px;
  box-shadow: 2px 2px 20px grey;
  border-radius: 5px;
  max-width: 1300px;
  min-width: 360px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

@media (min-width: 768px) {
  .scorebg{
    padding: 60px;
  }
}

.btn-primary{
  background: url('./img/Saved_Shortlist_Button.svg') no-repeat top left;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px !important;
  max-width: 300px;
  width: 270px !important;
  height: 50px !important;
  border: none !important;
  background-color: transparent !important;
  color: white !important;
  font-family: 'Overpass bold', sans-serif !important;
}
.shortlist-logo{
  width: 65px !important;
  height: 65px !important;
  display:inline-block;
  vertical-align: middle;
}

.shortlist-logo-icon{
  margin-top: 10px;
  background-image:url('./img/ShortList_Icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 35px !important;
  height: 35px !important;
  display:inline-block;
  float: left;
  margin-left: 20px;
}
@media screen and (min-width: 768px) {
  .shortlist-logo-icon{
    width: 45px !important;
    height: 45px !important;
    float: none;
    margin: 0;
    background-position: center;
  }
}
.shortlist-logo-text{
  color: black;
  font-family: 'Overpass', sans-serif;
}
.btn-left{
  padding: 0 !important;
  float: left;
  text-align: left;
}
.btn-left[type=button]{
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  
}
.btn-right{
  padding: 0 !important;
  float: right;
  text-align: right;
}
.btn-right[type=button]{
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  
}
.btn-hpi{
  background: url('./img/hpi.svg') no-repeat top left;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;

  font-size: 15px !important;
  max-width: 300px;
  width: 270px !important;
  height: 50px !important;
  color: white !important;
  font-family: 'Overpass bold', sans-serif !important;
}
.btn{
  font-family: 'Overpass bold', sans-serif !important;
}
.score-left{
  width: 50% !important;
}
.mi{
  width: 50%;
  text-align: center;
}
.btn-home{
  background: url('./img/RACC.png') no-repeat;
    background-position: center center;
    background-size: contain;
  margin-left: auto;
  margin-right: auto;

  font-size: 15px !important;
  max-width: 300px;
  width: 273px !important;
  height: 47px !important;
  background-color: transparent !important;
  color: white !important;
  font-family: 'Overpass bold', sans-serif !important;
}
/* .btn-home:hover{
  opacity: 0.9;

}
.btn-ea:hover{
  opacity: 0.9;

}
.btn-hpi:hover{
  opacity: 0.9;

} */
.btn-ea{
    background: url('./img/EATCC.png') no-repeat;
    background-position: center center;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;

    font-size: 15px !important;
    max-width: 300px;
    width: 270px !important;
    height: 45px !important;
    border: none !important;
    background-color: transparent !important;
    color: white !important;
    font-family: 'Overpass bold', sans-serif !important;
  
}
.coltext{
  font-size: 1.5em;
  max-width: 7rem;
  max-height: 30px;
  overflow: hidden;
  font-weight: bold;
  font-family: 'Overpass', sans-serif !important;
}
.overpass-font{
  font-family: 'Overpass', sans-serif;
  font-weight: 600;
  width: 100px;
  text-align: left;
  display: inline-block;
}
.inline{
  display: inline-block;
  width: 200px;
}
.inline-mobile{
  display: inline-block;
  width: 65%;
}
.coltextreg{
  font-size: 2em;
  max-width: 120px;
  overflow: hidden;
  font-family: 'UKNumberPlate', sans-serif;
}
.price-tag{
  height: 12px;
  width: 12px;
  float: left;
  display: inline-block;
  background-image:url('./img/Price.svg');
  background-repeat: no-repeat;
  margin-top: 5px;
  margin-right: 4px;
}
.card-bottom{
  margin-top: 10px;
  width: 100%;
  height: 40px;
  background-color: #ecf0f1;
  padding-left: 50px;
  padding-bottom: 20px;
}
.card-bottomm{
  margin-top: 10px;
  width: 100%;
  height: 100px;
  background-color: #ecf0f1;
  padding-left: 50px;
  padding-bottom: 20px;
}
.transp{
  background-color: transparent !important;
  width: 100%;
}
.mileage-tag{
  height: 10px;
  width: 12px;
  display: inline-block;
  background-image:url('./img/Mileage.svg');
  background-repeat: no-repeat;
  margin-right: 4px;
  margin-left: 10px;
}
.bgg{
  background-image:url('./img/Profile_Icon.svg');
  background-repeat: no-repeat;
  background-size: 100% !important;
  height: 100%;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.2;
}
.pp{
  width: 100%;
  height: 100px;
}
.btn{
  margin: 0;
  padding: 10px !important;
  width: 100%;
}
.bottom-border{
  border-bottom: solid lightgrey;
  width: 60%;
  text-align: left;
}
.bottom-border-mobile{
  border-bottom: solid lightgrey;
  width: 80%;
  text-align: left;
}
.vehicle-info-left{
  float: left;
  text-align: left;
  margin-bottom: 10px !important;
}
.fail-red{
  color: red;
}
.finance-info-left{
  float:left;
}
.pass-green{
  color: green;

}
.inline.fail-red,
.inline.pass-green{
  display: inline-block;
  margin-left: 0.5rem;
}

.cross-logo{
  height: 100px;
  width: 100px;
  background-color: white;
  border-radius:50px;
  margin-bottom: 10px;
  background-image:url('./img/Cross.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  vertical-align: middle;
}
.tick-logo{
  height: 100px;
  width: 100px;
  background-color: white;
  border-radius:50px;
  margin-bottom: 10px;
  background-image:url('./img/Tick-green.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  min-height: 200px;
  vertical-align: middle;
}
.tick-logo-mobile{
  width: 30px;
  float: right;
  border-radius:50px;
  margin-bottom: 10px;
  background-image:url('./img/Tick-green.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  vertical-align: middle;
  display: inline-block;
}
.cross-logo-mobile{
  width: 30px;
  float: right;
  border-radius:50px;
  margin-bottom: 10px;
  background-image:url('./img/Cross.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  vertical-align: middle;
  display: inline-block;
}
.car-logo-report{
  height: 100%;
  width: 100px;
  background-color: white;
  border-radius:50px;
  margin-bottom: 10px;
  background-image:url('./img/Cars.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  vertical-align: middle;
}
.car-logo-report-mobile{
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius:50px;
  margin-bottom: 10px;
  background-image:url('./img/Cars.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  float:right;
}
.car-logo-confirm{
  height: 80px;
  width: 80px;
  background-image:url('./img/Cars.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
height: 100%;
  vertical-align: middle;
}
.car-logo-confirm-container{
  width: 100px;
  line-height: 100%;
  vertical-align: middle;
  position: relative;
}
.card-font-small{
  font-size: 14px;
}
.signeabutton{
  font-size: 1vw;
}
.h{
  height: 100px;
  font-size: 5px;
  margin-bottom: 20px;
}
.y{
  margin-top:20px;
  font-size: 1vw;
  font-family: 'Overpass', sans-serif;
  color: grey;
}
.k{
  height: 70px;
}
.j{
  height: 30px;
}
.col-pic{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.scorebt{
  margin-top: 10px;
  height: 100%;
  width: 100%;
  float:right;
  background-color:#ffffff;
  padding: 10px;
  box-shadow: 3px 3px 10px lightgrey;
  border-radius: 10px;
}
.spacer{
  width: 100%;
  margin-top: 15px;
}
.rad{
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.radd{
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.spacerfont{
  font-size: 11px;
}
.scoreea{
  margin-top: 10px;
  height: auto;
  width: 100%;
  float:right;
  background-color:#ffffff;
  padding: 10px;
  box-shadow: 3px 3px 10px lightgrey;
  border-radius: 10px;
  background-image:url('./img/EA_Card.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  max-height: 300px;
}
.ad-link{
  color: black;
}
.bin-icon{
  background-image:url('./img/dustbin_white.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 13px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;

}
.bin-image{
  background-image:url('./img/dustbin.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 20px;
  float: right;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.bin-image:hover{
opacity: 0.6;
}

.card-body{
  margin: 0 !important;
  padding: 0 !important;
}
.hei{

  float: left !important;
  width: 40%;
 
}
.heii{
  width: 60% !important;

  float: right !important;
  padding: 5% !important;
  border-radius: 20px !important;
  
}
.container-login{
  width: 100%;
  padding: 10px;
  margin-left:auto;
  margin-right:auto;
  text-align: left;
}
.hchange{
  font-size: 2.2em;
}
.eatext{
  width: 90%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 1vw;
}
.curved-t-left{
  border-top-left-radius: 5px !important;

}
.curved-t-right{
  border-top-right-radius: 5px !important;

}
.curved-b-left{
  border-bottom-left-radius: 5px !important;

}
.curved-b-right{
  border-bottom-right-radius: 5px !important;

}

.tester{
  background-color:red;
}
.tester1{
  background-color:blue;
}
.tester2{
  background-color:green;
}
.pichei{
  height: 270px;
}
.ea-small{
  font-size: 8px;
  padding-bottom: 10px;
}
.boxcont{
  height: 200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
}
.boxcontt{
  margin-top: 20px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}
.roww{
  width: 100%;
  height: 100%;
  position: relative;
  background-color:yellow;
}




.middlecontain{
  width: 100%;
  height: 100px;
  margin-bottom: 30px;
  text-align: center;
}
.middlecontaincontain{
  width: 40%;
  /* float: left; */
  text-align: center;
}
.cards-text{
  margin:0;
  width: 100%;
  text-align: left;
}
.cards-text-reg{
  margin:0;
  text-align: center;
  font-family: 'UKNumberPlate', sans-serif;
  font-size: 25px;
}
.cards-texts{
  margin:0;
  width: 100%;
  text-align: right;
  float: right;
  min-width: 93px !important;
}
.cards-textss{
  margin:0;
  width: 100%;
  text-align: right;
  float: right;
  margin-top: 10px;
  min-width: 93px !important;
  color: black;
  background-color: #d2d2d2;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.btns{
  width: 80%;
  height: auto;
  background-color: red;
  margin-left: auto;
  margin-right: auto;
}
.btn-yes{
  width: 40%;
  height: 50px;
  float: left;
  background-color: #ffcc00;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  margin:5px;
  color: black;
}
.btn-no{
  width: 40%;
  height: 50px;
  float: right;
  background-color: grey;
  text-align: center;
  color: white;
  line-height: 50px;
  border-radius: 5px;
    margin:5px;
    color: black;
}
.btn-yes:hover{
opacity: 0.8;
}
.btn-no:hover{
  opacity: 0.8;
  }
  .btn-no[type=button]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
  
  }
  .btn-yes[type=button]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
  
  }
  .btn-yes[type=submit]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
  
  }
.toast-btn{
  width: 50px;
  height: 50px;
  background-color: red;
}
.go2294718771{
 margin-top: 40vh !important;
 width: 90%;
 background-color: rgb(0, 0, 31) !important;
 color: white !important;
 box-shadow: 1px 1px 5px 2px lightgrey !important;
} 
.Toastify__toast--error {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  width: 70vw !important;
  height: 70px;
  margin-top: 5.85em;
  background-color: rgb(28, 24, 53);
  color: white;
  vertical-align: middle;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
}
.Toastify__toast--warning {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  width: 70vw !important;
  height: auto;
  margin-top: 5.85em;
  background-color: rgb(28, 24, 53);
  color: white;
  bottom: 0;
  vertical-align: middle;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
}
.btn-padding{
  padding: 5px 5px 5px 15px
}
.Toastify__toast--success {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  width: 70vw !important;
  height: 70px;
  margin-top: 5.85em;
  background-color: rgb(28, 24, 53);
  color: white;
  vertical-align: middle;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
}
.Toastify__toast--info {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  width: 70vw !important;
  height: 100%;
  margin-top: 5.85em;
  background-color: rgb(28, 24, 53);
  color: white;
  vertical-align: middle;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
}
.car-contain{
  height: 100%;
  width: 60px;
  margin-right: 10px;
  float: left;
  vertical-align: middle;
  padding-left: 15px;
}
.car-img{
  width: 60px;
  height: 60px;
  background-image:url('./img/Info.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;

}
.car-img-error{
  width: 60px;
  height: 60px;
  background-image:url('./img/Error.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
}
.car-img-success{
  width: 60px;
  height: 60px;
  background-image:url('./img/Sucess.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
}
.car-img-text{
height: 100%;
vertical-align: middle;
text-align: center;
line-height: 30px;
font-size: 1vw;
}
.cards-text-bold{
  font-family: 'Overpass bold', sans-serif;
  font-weight: bold;
  margin:0;
  width: 100%;
  text-align: left;
}
.sort-by{
  width: 100%;
  display: inline-block;
  text-align: right;
}
.sort-by-text{
  display: inline-block;
  margin-right: 10px;
}
.sort-box{
  border-color: lightgrey;
  border-radius: 5px;
}
.half{
  width: 30% !important;
  margin-top: 10px;
  display: inline-block !important;
  text-align: right;
}
.halff{
  width: 65% !important;
  margin-left: 10px;
  display: inline-block !important;
  margin-top: 10px;
}
.ad-container{
  margin-top: 10px;
  width: 200px;
  height: 30px;
  background-color: rgb(231, 231, 231);
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  float: right;
  vertical-align: middle;
  line-height: 30px;
}
.int-logo{
  width: 25px;
  height: 25px;
  background-image:url('./img/www.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2px;
  margin-left: 5px;
  display: inline-block;
  float: left;
}
.int-link{
 float: right;
 height: 30px;
 width: 150px;
  margin-right: 5px;
  margin-left: 5px;
  display: inline-block;
}
.edit-button[type=button]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;

}
.delete-button[type=button]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;

}
.edit-button{

  background-color: #ecf0f1;
  border-radius: 5px;
  width: 70px !important;
  vertical-align: middle;
  line-height: 35px;
  height: 35px;
  margin-right: 5px;
  margin-left: 5px;
  display: inline-block;
float: right;
text-align: center;
}
.edit-button:hover{
opacity: 0.8;
}
.delete-button{
  padding-top: 10px;
  height: 35px;
  background-color: #cc0033;
  border-radius: 5px;
  width: 70px;
  display: inline-block;
  float: right !important;
  margin-bottom: 5px;
  align-self: flex-end;
}

.delete-button:hover{
  opacity: 0.8;
}
.shortlist-save-btn,
.shortlist-cancel-btn{
  margin-top: 18px;
}
.Toastify__toast-container {
  height: auto;
  width: 100vw;
  position: absolute;
  
}
.row-card{
 margin-left: auto !important;
 margin-right: auto !important;
}
.row-card-desktop{
  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 20px;
  position: relative;
 }

@media (max-width: 767px){
  .row-card-desktop > .col-sm-2{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 0;
    margin: 10px;
  }

  .row-card-desktop .cross-logo,
  .row-card-desktop .car-logo-report,
  .row-card-desktop .tick-logo{
    width: 100%;
    min-height: 0;
  }
}

 .desktop-padding{
   padding-bottom: 20px !important;
 }
.cards{
  width: 100%;                
  height: auto;
  background-color: white !important;
  box-shadow: 1px 1px 5px 2px lightgrey;
  margin-left: auto;
  margin-right:auto;
  margin-bottom: 20px;
  overflow:hidden;
  border-radius: 5px;
}
.card-box input{
background-color: white !important;
font-size: 1em;
height: 1.5em;
width: 100%;
}
.card-boxx input{
  background-color: white !important;
  font-size: 1em;
  height: 1.5em;
  width: 60%;
  }
.middlecontaincontainc{
  width: 100%;
  height: 100%;
  float: left;
  text-align: center;
  background-color: lightgray;
}
.middlecontaincontainr{
  width: 30%;
  height: 100%;
  float: right;
  text-align: center;
  background-color: darkgray;
}
.btn-info{
  background-color: #000032 !important;
  border: none !important;
}
.btn-info:hover{
  opacity: 0.8;
}
.bottomcontain{
  width: 100%;
  height: 150px;
}
.black{
  height: auto !important;
}
.darker{
  background-color: #f4f4f4 !important;
}

.gap{
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
  padding: 0px !important;
  padding-top: 40px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  

}
.wide{
  width: 100% !important;
  background-color: red !important;
} 
.centre{
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}
.home-three{
height: 100px !important;
margin-left: auto;
margin-right: auto;


}
.blank{
  background-color: white !important;
}
.transparent{
  background-color: transparent !important;
  margin-left: auto;
  margin-right: auto;
}
.pad{
  padding: 5px;
}
.accord-contain{
  width: 90%;
  height: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.MobileNav_MoNavContainer__1ilEr{
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
  background-color: rgba(255,204,0, 0.9);

}
.MobileNav_MoNavLinks__mNUdF{
  height: 200px;
  background-color: rgba(255,204,0, 0.9);
}
.MobileNav_mobileNav__1-78d{
  height: 100px !important;
}
.MobileNav_MoNavLinks__mNUdF li{
  border-bottom: transparent !important;
  text-align: center;
  font-size: 30px;
  font-family: 'Overpass', sans-serif;
}
.MobileNav_navBars__31q_E{
  margin-top: 15px;
  float: right !important;
}
.MobileNav_navBars__31q_E svg{
float: right;
margin-right: 50px !important;
color: darkblue !important;
}
.MobileNav_MoNavLogo__C8_0h{
  margin-top: 15px;
  height: 80px;
}
.MobileNav_MoNavLogo__C8_0h img{
  height: 75px !important;
}
.colourr{
  background-color: white!important;
 border-left: none !important;
 border-right: none !important;
 border-top: none !important;
}

.MobileNav_MoNavSocial__ozVHB{
  float: left !important;
}
.grey{
  background-color: #e9e9e9!important;
}
.lgrey{
  background-color: #f4f4f4 !important;
}
.radius{
  border-radius: 20px !important;
  width: 100% !important;
  height: 50px !important;
 
}
.score-reg{
  font-size: 3.8em !important;
  padding-top: 5px;
  font-family: 'UKNumberPlate', sans-serif;
  text-align: center;
}
.cont{
  text-align: center;
}
.reg-background{
  width: 300px;
  height: 70px;
  background-image:url('./img/pngreg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 7px;
  margin-left:15px;
}
.shortlist-save-btn{
 width: 70px;
 height: 45px;
 background-color: #ffcc00 !important;
 margin-right: 10px;
}
.shortlist-cancel-btn{
  width: 70px;
  height: 45px;
  
  background-color: rgb(171, 171, 171) !important;
  
 }
.capped{
  width: 10em;
  overflow: hidden;
  max-height: 25px !important;
  color: black;
  font-family: 'Overpass', sans-serif;
  font-weight: 600;
}
.reg-backgroundd{
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 7px;
}
.left{
  float: left !important;
  text-align: left;
  padding-left: 20px !important;
  font-size: 0.7em !important;
  font-family: 'Overpass', sans-serif !important;
  font-weight: 700;
  margin: 0;
  line-height: 35px;
}
.lefty{
  float: left !important;
  text-align: left;
  padding-left: 20px !important;
  font-family: 'Overpass', sans-serif !important;
}
.margin-left{
  margin-left: 20px;
}
.pad-top{
  padding-top: 30px;
}
.filter-btn{
  width: 76%;  
  height: 40px;
  line-height: 40px;
  background-color: #ffcc00;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px 2px lightgrey;
  border-radius: 5px;
  padding-right: 10px;
}
.filter-seg{
  width: 33%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  margin-bottom: 40px;
}
.leftyy{
  float: left !important;
  text-align: left;
  width: 80%;
  padding-left: 20px !important;
  font-family: 'Overpass', sans-serif !important;
}
.circle-yellow{
  height: 6px;
  width: 6px;
  background-color: #ffcc00;
  float: left;
  margin-right: 5px;
  border-radius: 20px;
  margin-top: 5px;
  line-height: 10px;
  vertical-align: middle;
}
.fift{
  width: 50% !important;

}
.i-logo-home{
  width: 14px;
  height: 14px;
  background-image:url('./img/Info_Expand.svg');
  background-size: contain;
  background-color: grey ;
  border-radius: 40px;
  display: inline-block;
  float: right;
  margin-right: 4rem;
  margin-top: 20px;
  vertical-align: middle;
}
.i-logo{
  width: 20px;
  height: 20px;
  background-image:url('./img/Info_Expand.svg');
  background-size: contain;
  background-color: grey ;
  border-radius: 40px;
  display: inline-block;
  float: left;
  vertical-align: middle;
}
.i-text{
width: 80%;
line-height: 22px;
vertical-align: middle;
display: inline-block;
float: left;
margin-left: 5px;
text-align: left;
font-size: 0.8rem;
}
.background-password h1{
  text-align: center !important;
  width: 50% !important;
  margin-left: auto;
  margin-right: auto;
}
.background-password{
  text-align: center !important;
  width: 80% !important;
  margin-left: auto;
  margin-right: auto;
}
.password-changed-logo{
  width: 15rem;
  height: 15rem;
  padding: 50px;
  background-image:url('./img/TICK.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.spacer{
  margin-top: 10px !important;
}

.maxed{
  max-height: 50px !important;
}
.colour{
  background-color: white!important;
 border-left: none !important;
 border-right: none !important;
}
.thumb{
  width: 100%;
  background-image: url('./img/Thumbb.png') !important;
  background-size: 100%;
}
.arrow-down{
  width: 30px;
  height: 37px;
  float: right !important;
  display: inline;
  background-image: url('./img/Down_Arrow.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-right: 10px;
}
.right-mot-history{
  width: 50%;
  float: right !important;
}
.mot-history{
  width: 50%;
  float: left !important;
  font-size: 2rem;
}
.right{
  float: right !important;
  width: 10% !important;
}
.third{
  width: 45% !important;
  float: left;
  max-width: 160px;
}
.thirdd{
  width: 45% !important;
  font-size: 2em !important;
 
}
.col-right{
  width: 40% !important; 
  padding:10px;
  font-size: 30px;

  
}
.col-left{
  width: 30% !important;
  padding:10px;
  font-size: 30px;
  margin-left: 15px;
}
.accord{
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.accord-right{
float: right !important;
}
.accord-left{
  float: left !important;
}
.home-font{
font-size: 15px;
}
.wid{
  width:100% !important;
  height: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.margin{
  margin-top: 50px !important;
 height: auto !important;

}
.white{
  color:white;
  font-family: 'Overpass', sans-serif;
}
.reviews{
  width: 100%;
  height: 200px;
  background-color: darkblue;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.image-right{
  float: right;
  background-image:url('./img/Thumb.png');
  background-repeat: no-repeat;
  background-size: cover !important;
  width: 100% !important;
  height: auto !important;
  overflow: visible !important;
  
  max-height: 300px !important;
  max-width: 508px !important;
  padding: 30px !important;
}
.text-left{
  width: 50%;
  height: auto !important;
  padding: 10px;
  margin-left: 5.5vw;
  float:left;
}
.container-centre{
  background-color: green;
  width: 100%;
  height: auto;
}
.text-contain{
  width: 90%;
  float:right;
}
.logo-space{
  width: 10%;
  height: 50px;
  background-color: red;
  float:left;
  display: inline;
  border-radius: 20px;
}
.faq{
  width: 70% !important;
  margin-left:auto;
  margin-right: auto;
  padding-bottom: 50px;
}
.backgr{
width: 100%;
background-size: 100%;
position: relative;
}
.header{
  width: 100%;
  text-align: center;
  height: 80px;

}
.ad{
  width: 70%;
  height: 150px;
  background-color: grey;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}
.three{
  background-color: red;
}
.two{
  background-color: #ffcc00 !important;
  border-radius: 10px !important;
  padding-top: 12px;
  text-align: center;
  min-width: 840px;
  box-shadow: 1px 1px 5px 1px lightgrey;
}
.one{
  background-color: transparent;
  /* border-bottom: solid 2px black; */
  border-radius: 0px;
}
.starsimage{
  width: 100%;
  max-width: 400px;
  height: auto;
  text-align: left;
  float:left;
  padding-bottom: 20px;
}
.top-gap{
  padding-top: 30px;
}
.text-left-check{
  text-align: left !important;
  float:left !important;
}
.example-box{
  width: 100%;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px;
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 20px;
  overflow:scroll;
}

.under{
  border-bottom: 2px solid rgb(255, 187, 0);
  text-align: right;
}

.testy{
  background-color: red !important;
}
.fullvehhead{
  border-bottom: 2px solid rgb(255, 187, 0);
  width: 50%;
  float:left;
  text-align: center;
}
.underr{
  border-bottom: 2px solid lightgray !important;
  width: 50%;
  float:right;
}
.full-check-top{
  border-bottom: 2px solid lightgray !important;
  width: 50%;
  float:right;
}
.regsignuph{
  font-size: 3em !important;
  margin-top: 10px !important;
  min-width: 212px;
  font-family: 'Overpass bold', sans-serif;
}
.regsignuphh{
  font-size: 3vw !important;
  margin-top: 10px !important;
  border-bottom: solid #ffcc00;
}
.result{
  color: red !important;
  font-size: 14px; 
}
.result-pass{
  color: green !important;
  font-size: 14px;
  font-weight: 600;
}
.sta{
  margin-top: 10px;
  text-align: center;
}
.sl{
  float:left;
}
.col{
  text-align: center;
  font-weight: bold;
  height: 50px;
}
p{
  font-family: 'Overpass', sans-serif;
  font-weight: lighter;
}
h4{
  font-family: 'Overpass bold', sans-serif;
}
h5{
  font-family: 'Overpass bold', sans-serif;
}


.form-group:input{
  background-color:#ecf0f1;
  border: none;
}


/* .nav-dropdown{
width: 100%;
background-color: red;
color: green;
} */

.signinpic a{
  color: transparent;
}
.signinpic a:hover{
  color: transparent;
}
.bar a{
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.bar a:hover{
  text-decoration: none;
  background-color: #e9ecef;
  color: #212529;
}

.bar a p{
  margin: 0.5rem 0;
  line-height: 1;
}



/* GOOGLE BUTTONS */
.google-button {
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  background: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 8px;
  white-space: pre-wrap;
}
.cookie{
  background-color: red;
  color: white;
  padding: 5px 10px;
  margin: 15px;
  top: 0;
}
.cookie:hover{
  color: white;
}
.dialog{
  background-color: #ecf0f1;
  width: 100%;
  height: 50px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  line-height: 50px;
}.confirm{
  width: 10%;
  height: 30px;
  color: white;
  background-color: #273c75;
  margin:5px;
  float: right;
  display: inline;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 30px;
  box-shadow: 2px 2px 5px black;
}
.confirm:hover{
  opacity: 0.8;
}
.confirm-text{
  width: 70%;
  display: inline;
  font-size: 1.5em;
  max-width: 300px !important;
}
.yes{
  width: 50px !important;
  height: 50px !important;
  background-color: black !important;
  display: inline;
}


/* MEDIA TAGS */
@media only screen and (min-width: 1650px){
  .eatext{
    font-size: 16px;
  }
}

.enquiry-assistant{
  align-items: center;
}

.enquiry-assistant >.col{
  display: flex;
  align-items: center;
  padding: 0!important;
}

.enquiry-assistant-row{
  display: flex;
  width: 100%;
}

.container-score strong{
  display: block;
  margin-bottom: 0.5rem;
}

.terms-conditions input{
  vertical-align: middle;
}

.terms-conditions a{
  margin-left: 10px;
  line-height: 22px;
  vertical-align: middle;
  color: #333;
}

.react-cookie-law-manage-btn{
  background: transparent !important;
  color: gray !important;
  text-decoration: underline !important;
}

.react-cookie-law-accept-btn{
  display: inline-block;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(97, 162, 41) !important;
  margin: 0 20px !important;
}

.react-cookie-law-accept-btn:hover{
  background-color: rgb(97, 162, 41) !important;
}

.react-cookie-law-option-wrapper{
  margin: 0 10px !important;
}

.react-cookie-law-option-wrapper .react-cookie-law-option-checkbox{
  top: 6px !important;
}

.react-cookie-law-option-wrapper label{
  margin: 0 !important;
  vertical-align: middle !important;
}

.react-cookie-law-select-pane{
  padding: 0 !important;
  vertical-align: middle !important;
}
.short-list-tooltip{
  background-color: #000032;
  display: block;
  margin: 20px auto -20px;
  padding: 20px;
  border-radius: 6px;
  color: white;
  position: relative;
}

.short-list-tooltip a,
.short-list-tooltip a:hover{
  color: white;
  padding: 0 5px;
  text-decoration: underline;
}

.short-list-tooltip-close,
.short-list-tooltip-close:hover{
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 16px;
  display: block;
  text-decoration: none !important;
  cursor: pointer;
  color: white;
}

.car-details-table{
  padding: 32px 25px 20px !important;
  border-radius: 5px;
}

@media screen and (min-width: 768px) {
  .car-details-table{
    padding: 35px 25px 40px !important;
    border-radius: 5px;
  }
}


.car-details-row{
  background-color: #f4f4f4 !important;
  margin: 0 !important;
}

.car-details-row:nth-child(odd) {
  background-color: #e9e9e9 !important;
}

.car-details-table-round {
  border-radius: 5px;
  overflow: hidden;
  margin: 0 -15px;
}

.car-details-row div{
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .car-details-row div{
    padding: 17px;
  }
}

.car-details-row h6{
  font-size: 12px;
  text-align: left;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .car-details-row h6{
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .car-details-buttons .sta{
    margin-top: 0;
  }
}


